<template>
    <div>
        <ToolsNav />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter fill">
            <h1>Credit Application</h1>
            <p>If you are a distributor, and are interested in opening a line of credit with CDI please fill out and return the credit application (below). You may have a standard credit application already completed for your company, you may send that to CDI in place of filling out this application. When sending CDI a credit application please also include your resale certificate.</p>
            <a :href="`${$cdiVars.BASE_URL}/pdf/CDI_CREDIT_APPLICATION.pdf`">Download Credit Application</a>

        </main>
    </div>
</template>

<script>
    import ToolsNav from '@/components/content/ToolsNav.vue'
    export default {
        name: "CreditApp",
        components: {
            ToolsNav,
        },
        created() {
            this.$appStore.setTitle(['CDI Credit Application']);
        },
    }
</script>

<style scoped>

</style>